import "../index.css";
import ClayLayout from "@clayui/layout";
import { ClayTooltipProvider } from '@clayui/tooltip';
import { useEffect, useState } from "react";
import { getShortText, getPersonas, getTooltipPersona } from "../utils/util";

const Liferay = window.Liferay;

const LearningPathsList = ({ paging, page, pageSize, durationFormat }) => {
  const [learningPaths, setLearningPaths] = useState(null);

  useEffect(() => {

    Liferay.Util.fetch(
      `/o/c/learningpaths/scopes/${Liferay.ThemeDisplay.getScopeGroupId()}?page=1&pageSize=3&fields=id,description,level,persona,title`
    )
      .then((response) => response.json())
      .then((response) => {
        setLearningPaths(response.items);
      });
  }, []);

  return (
    <ClayLayout.ContainerFluid view>
      <ClayLayout.Row justify="start">
        {learningPaths && learningPaths.length > 0 && (
          <>
            {learningPaths.map((learningPath, index) => {
              return (
                <ClayLayout.Col
                  xl={4}
                  lg={4}
                  md={6}
                  sm={12}
                  xs={12}
                  key={index}
                  className="d-flex course-layout-col"
                >
                  <a href={`/l/${learningPath.id}`} id="course-container-link">
                    <div className="course-container d-flex learn-education__landing-card">
                      <div className="course-content-card d-flex flex-column justify-content-between">
                        <h4 className="title">{learningPath.title}</h4>
                        <div className="description">
                          {getShortText(learningPath.description, 200)}
                        </div>

                        <div
                          className="d-flex information learn-education"
                          key={learningPath.id}
                        >
                          <ClayTooltipProvider>
                            <div className="card-tag card-tag__persona" data-tool-tip-align="top" title={getTooltipPersona(learningPath.persona)}>
                              <p></p>
                              {getPersonas(learningPath.persona)}
                            </div>
                          </ClayTooltipProvider>
                          <div
                            className={`card-tag card-tag__expertise-${learningPath.level.name.toLowerCase()}`}
                          >
                            <p>{learningPath.level.name}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
                </ClayLayout.Col>
              );
            })}
          </>
        )}
      </ClayLayout.Row>
    </ClayLayout.ContainerFluid>
  );
};

export default LearningPathsList;