const UseSanitizeHTML = (html) => {

    const allowedTags = [
        "a",
        "b",
        "br",
        "code",
        "em",
        "h1",
        "h2",
        "h3",
        "h4",
        "h5",
        "h6",
        "i",
        "li",
        "ol",
        "p",
        "span",
        "strong",
        "u",
        "ul",
    ];
    const parserDOM = new DOMParser();

    const documentDOM = parserDOM.parseFromString(html, "text/html");

    const elementsDOM = documentDOM.body.querySelectorAll("*");

    elementsDOM.forEach((element) => {
        if (!allowedTags.includes(element.tagName.toLowerCase())) {
            element.remove();
        } else {
            const allowedAttributes = {
                a: ["href", "target"],
                "*": ["style"],
            };

            Array.from(element.attributes).forEach((attribute) => {
                const tagAttributes = allowedAttributes[element.tagName.toLowerCase()] || [];
                const globalAttributes = allowedAttributes["*"] || [];
                if (![...tagAttributes, ...globalAttributes].includes(attribute.name)) {
                    element.removeAttribute(attribute.name);
                }
            });
        }
    });

    return documentDOM.body.innerHTML;
};

export default UseSanitizeHTML;