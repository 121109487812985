import "../index.css";
import ClayLayout from "@clayui/layout";
import { ClayTooltipProvider } from "@clayui/tooltip";
import { getShortText, getPersonas, getTooltipPersona } from "../utils/util";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getAllCoursesAndFirstLessons } from "../services/course";
import { getLearningPathPage } from "../services/learning-path";
import { getCertificationsList } from "../services/certification";

const LandingPageCardList = ({ type }) => {
  const [educationSectionCards, setEducationSectionCards] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    const fetchType = async (type) => {
      if (type === "course") {
        const data = await getAllCoursesAndFirstLessons();

        const courseData = data
          .filter((course) => course.lesson && course.r_module_c_course)
          .sort(
            (a, b) =>
              a.r_module_c_course.position - b.r_module_c_course.position
          )
          .map((course) => ({
            ...course.r_module_c_course,
            firstLesson: course.lesson[0].id,
          }));

        setEducationSectionCards(courseData);
      } else if (type === "learningPath") {
        const data = await getLearningPathPage();

        setEducationSectionCards(data.items);
      } else {
        const data = await getCertificationsList();

        setEducationSectionCards(data);
      }
    };

    fetchType(type);
  }, [type]);

  return (
    <div className="container-cards-page">
      {educationSectionCards && educationSectionCards.length > 0 && (
        <>
          {educationSectionCards.map((educationSectionCard, index) => {
            return (
              <ClayLayout.Col className="card-type" key={index}>
                <div className={`tag-${type}`}>{t(`${type}`)}</div>
                <a
                  href={`/l/${
                    educationSectionCard.firstLesson
                      ? educationSectionCard.firstLesson
                      : educationSectionCard.id
                  }`}
                  id="type-container-link"
                >
                  <div className="type-container d-flex learn-education__landing-card">
                    <div className="type-content-card d-flex flex-column justify-content-between">
                      <h4 className="title">
                        {educationSectionCard.title === "" ? educationSectionCard.name : educationSectionCard.title}
                      </h4>
                      <div className="description">
                        { type !== 'learningPath'
                          
                            ? getShortText(
                              educationSectionCard.description === "" 
                                ? educationSectionCard.description_i18n.en_US
                                : educationSectionCard.description
                                , 150 
                              )

                            : getShortText( educationSectionCard.description, 150 )
                          
                        }
                      </div>
                      <div
                        className="d-flex information learn-education"
                        key={educationSectionCard.id}
                      >
                        <ClayTooltipProvider>
                          <div
                            className="card-tag card-tag__persona"
                            data-tool-tip-align="top"
                            title={getTooltipPersona(
                              educationSectionCard.audience
                                ? educationSectionCard.audience
                                : educationSectionCard.persona
                            )}
                          >
                            <p></p>
                            <span>
                              {getPersonas(
                                educationSectionCard.audience
                                  ? educationSectionCard.audience
                                  : educationSectionCard.persona
                              )}
                            </span>
                          </div>
                        </ClayTooltipProvider>
                        {educationSectionCard.level && (
                          <div
                            className={`card-tag card-tag__expertise-${educationSectionCard.level.name.toLowerCase()}`}
                          >
                            <p>{educationSectionCard.level.name}</p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </a>
              </ClayLayout.Col>
            );
          })}
        </>
      )}
    </div>
  );
};
export default LandingPageCardList;
