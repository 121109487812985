import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import arJSON from './locale/ar.json'
import enJSON from './locale/en.json'
import esJSON from './locale/es.json'
import jpJSON from './locale/jp.json'
import {getCurrentLanguageKey} from "./utils/util";


i18n.use(initReactI18next).init({
    resources: {
        ar: { ...arJSON },
        en: { ...enJSON },
        es: { ...esJSON },
        jp: { ...jpJSON},
    },
    lng: getCurrentLanguageKey(),
});
