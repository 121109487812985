/* eslint-disable array-callback-return */
import "../../styles/CertificationsDetails.css";
import { convertMinutesToDuration } from "../../utils/util";
import UseSanitizeHTML from "../Hooks/UseSanitizeHTML";
import { useTranslation } from "react-i18next";

const CertificationsDetailsCard = ({duration, title, expandedCards, description, toggleDescription, learningPaths, assessmentId, url}) => {

    const {
        t,
      } = useTranslation();


    const previewText = (textDescription) => {
        return textDescription.substring(0, 50) + "...";
    } 

    return (
        <>
            <div className="certifications-details-card">
                    <div className="tag-duration-certifications">
                        <p>
                            {convertMinutesToDuration(duration, "hours")}
                        </p>
                    </div>
                    <h2>{title}</h2>
                <div>
                    <div className="description-card-certifications-details">
                   
                        <p
                            dangerouslySetInnerHTML={{
                                __html: 
                                    UseSanitizeHTML(expandedCards
                                        ? description
                                        : previewText(description)),
                            }}
                        ></p>

                    </div>
                    <p onClick={toggleDescription}
                        style={{
                            cursor: "pointer",
                            fontWeight: 600,
                            textDecoration: "underline",
                        }}
                    >
                        {expandedCards ? "Show Less" : "Show More"}
                    </p>
                </div>

                <div className="buttons-card-certifications-details">
                    {learningPaths.map((learningPath) => {
                        if(learningPath.r_p2S3AssessmentToP2S3LearningPaths_c_p2s3AssessmentId > 0 &&
                            learningPath.r_p2S3AssessmentToP2S3LearningPaths_c_p2s3AssessmentId === assessmentId) {

                            return ( 
                                <a
                                    key={learningPath.id}
                                    href={`/l/${learningPath.id}`} 
                                    className="button-learning-path"
                                >

                                    {title} &nbsp;
                                    {t("learning-path")}
                                </a>
                            );
                    }})}
                        <a href={url} className="button-take">
                            <span>{t("take")} {title}</span>
                        </a>
                </div>
            </div>
        </>
    )
}

export default CertificationsDetailsCard;