import { request } from "../utils/request";
import { config } from "../utils/constants";
import { getCurrentSiteId, getCurrentUserId } from "../utils/util";

export const getBadgeById = (badgeId) => {
  return request({
    url: `${config.badgeEndpoint}${badgeId}`,
    method: "get",
  });
};

export const getUserBadgesByUserId = (userId) => {
  return request({
    url: `${
      config.userBadgeEndpoint
    }scopes/${getCurrentSiteId()}?filter=r_userBadges_userId eq '${userId}'`,
    method: "get",
  });
};

export const getUserBadgeByUserIdAndQuizId = (quizId) => {
  return request({
    url: `${
      config.userBadgeEndpoint
    }scopes/${getCurrentSiteId()}?filter=r_userBadges_userId eq '${getCurrentUserId()}' and quizId eq ${quizId}`,
    method: "get",
  });
};

export const postUserBadge = (badgeId, quizId) => {
  return request({
    url: `${config.userBadgeEndpoint}scopes/${getCurrentSiteId()}`,
    method: "post",
    data: {
      r_userBadges_userId: getCurrentUserId(),
      badgeId: badgeId,
      quizId: quizId,
    },
  });
};


export const getBadgeByQuizId = async (quizId) => {
  const data = await request({
    url: `${config.quizEndpoint}/${quizId}/quizBadge`,
    method: "get",
  });
  return data?.items[0].id;
};
