/* global Liferay */
import "../index.css";
import ClayLayout from "@clayui/layout";
import { ClayTooltipProvider } from '@clayui/tooltip';
import { getShortText, getPersonas, getTooltipPersona } from "../utils/util";
import { useEffect, useState } from "react";
import { getAllCoursesAndFirstLessons } from "../services/course";

const CoursesList = () => {
  const [courses, setCourses] = useState(null);

  useEffect(() => {
    const fetchCourses = async () => {
      const data = await getAllCoursesAndFirstLessons();

      const courses = data
        .filter((course) => course.lesson && course.r_module_c_course)
        .sort(
          (a, b) => a.r_module_c_course.position - b.r_module_c_course.position
        )
        .slice(0, 3);

      setCourses(courses);
    };

    fetchCourses();
  }, []);

  return (
    <ClayLayout.ContainerFluid view>
      <ClayLayout.Row justify="start">
        {courses && courses.length > 0 && (
          <>
            {courses.map((course, index) => {
              return (
                <ClayLayout.Col
                  xl={4}
                  lg={4}
                  md={6}
                  sm={12}
                  xs={12}
                  key={index}
                  className="d-flex course-layout-col"
                >
                  <a
                    href={`/l/${course.lesson[0].id}`}
                    id="course-container-link"
                  >
                    <div className="course-container d-flex learn-education__landing-card">
                      <div className="course-content-card d-flex flex-column justify-content-between">
                        <h4 className="title">
                          {course.r_module_c_course.title === "" ? course.r_module_c_course.name : course.r_module_c_course.title}
                        </h4>
                        <div className="description">
                          {getShortText(
                            course.r_module_c_course.description === ""
                            ? course.r_module_c_course.description_i18n.en_US
                            : course.r_module_c_course.description,
                            150
                          )}
                        </div>
                        <div
                          className="d-flex information learn-education"
                          key={course.r_module_c_course.id}
                        >
                          <ClayTooltipProvider>
                            <div className="card-tag card-tag__persona" data-tool-tip-align="top" title={getTooltipPersona(course.r_module_c_course.audience)}>
                              <p></p>
                              {getPersonas(course.r_module_c_course.audience)}
                            </div>
                          </ClayTooltipProvider>
                          <div
                            className={`card-tag card-tag__expertise-${course.r_module_c_course.level.name.toLowerCase()}`}
                          >
                            <p>{course.r_module_c_course.level.name}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
                </ClayLayout.Col>
              );
            })}
          </>
        )}
      </ClayLayout.Row>
    </ClayLayout.ContainerFluid>
  );
};

export default CoursesList;
