import {getServerUrl, oAuthRequest, request} from "../utils/request";
import {config} from "../utils/constants";
import { getCurrentSiteId } from "../utils/util";


export const getCourseFirstLesson = (courseId) =>{

    let serviceBaseAddress = getServerUrl();

    return oAuthRequest({
        url:`${serviceBaseAddress}${config.navigationMenuEndPoint}lessonOne/${courseId}`,
        method:'get'
    });

}

export const getLearningPathBreakdown = (learningPathId) =>{

    let serviceBaseAddress = getServerUrl();

    return oAuthRequest({
        url:`${serviceBaseAddress}${config.navigationMenuEndPoint}learning-path/${learningPathId}/breakdown`,
        method:'get'
    });

}

export const getLearningPathStepBreakdown = (learningPathStepId) =>{

    let serviceBaseAddress = getServerUrl();

    return oAuthRequest({
        url:`${serviceBaseAddress}${config.navigationMenuEndPoint}learning-path-step/${learningPathStepId}/breakdown`,
        method:'get'
    });

}

export const getNavigationMenu = (assetId,assetType) =>{
    return request({
        url: `${config.courseEndpoint}scopes/${getCurrentSiteId()}?filter=module/${assetType}/id eq '${assetId}'&nestedFields=module,lesson,quiz&nestedFieldsDepth=2&fields=module.id,module.name,module.title_i18n,module.title,module.position,module.lesson.id,module.lesson.title,module.lesson.name,module.lesson.title_i18n,module.lesson.position,module.quiz.id,module.quiz.title,module.quiz.title_i18n,module.quiz.position`,
        method:'get' 
    })
}

export const getCoursesPaged = (page,pageSize) => {

    let serviceBaseAddress = getServerUrl();

    return oAuthRequest({
        url:`${serviceBaseAddress}/menu/courses?page=${page}&pageSize=${pageSize}`,
        method:'get'
    });

}

export const getLearningPathsPaged = (page,pageSize) => {

    let serviceBaseAddress = getServerUrl();

    return oAuthRequest({
        url:`${serviceBaseAddress}/menu/learning-paths?page=${page}&pageSize=${pageSize}`,
        method:'get'
    });

}
